import { AppLocale } from '@procsea/common/utils'
import { MyOptionType } from '@procsea/design-system'

import { TranslatedValue } from 'src/components/presentationals/shared/fields'

import { CustomerCategory, CustomerCategoryAPI } from './customerCategory'
import { InvoiceStatuses } from './invoiceRecord'
import { WeekDays } from './weekdays'

export enum InvoiceCycle {
  EDI = 'edi',
  DAILY = 'daily',
  BY_ORDER = 'by_order',
  WEEKLY = 'weekly',
  DECADE = 'decade',
  FORTNIGHT = 'fortnight',
  MONTHLY = 'monthly',
}

export enum PaymentTermsCycle {
  DAILY = 'daily',
  DECADE = 'decade',
  FORTNIGHT = 'fortnight',
  MONTHLY = 'monthly',
  WEEKLY = 'weekly',
}

export interface BuyerCompanyAPI {
  address: string
  buyer_count: number
  can_use_chat: boolean
  can_use_gln_association: boolean
  commercial_tags: string[]
  contract_language: AppLocale | null
  count_future_orders: number
  country: string
  created_from_ecosystem_id: number
  currency: string
  customer_category: CustomerCategoryAPI | null
  default_marketplace_id: Id | null
  delivery_address_country: string
  delivery_address_line: string
  delivery_address_locality: string
  delivery_address_zip: string
  delivery_code: string
  delivery_comments: string
  delivery_note_with_prices: boolean
  delivery_phone: string
  delivery_time_from: string
  delivery_time_to: string
  delivery_time: string
  editable: boolean
  group_tags: string[]
  has_connected_route: boolean
  id: Id
  internal_reference: string
  invoice_cycle: InvoiceCycle
  is_demo_company: boolean
  legal_name: string
  locality: string
  marketplace_ids: Id[]
  name: string
  option_flag_customer_can_edit_prices: boolean
  organisation_id: Id | null
  organisation_pusher_uuid: string
  payment_terms_cycle: PaymentTermsCycle
  payment_terms_days: number
  registration_number: string
  require_one_invoice_per_order: boolean
  scheduler_starting_iso_day: WeekDays
  special_footnote_for_invoice: TranslatedValue
  use_only_fixed_weight_boxes: boolean
  uuid: string
  vat_number: string
  vat_zone_id: Id
  vertical_ids: Id[]
  zip_code: string
}

export interface BuyerCompany {
  address: BuyerCompanyAPI['address']
  buyerCount: BuyerCompanyAPI['buyer_count']
  canUseChat: BuyerCompanyAPI['can_use_chat']
  canUseGlnAssociation: BuyerCompanyAPI['can_use_gln_association']
  commercialTags: BuyerCompanyAPI['commercial_tags']
  contractLanguage: BuyerCompanyAPI['contract_language']
  countFutureOrders: BuyerCompanyAPI['count_future_orders']
  country: BuyerCompanyAPI['country']
  createdFromEcosystemId: BuyerCompanyAPI['created_from_ecosystem_id']
  currency: BuyerCompanyAPI['currency']
  customerCategory: CustomerCategory | null
  defaultMarketplaceId: BuyerCompanyAPI['default_marketplace_id']
  deliveryAddressCountry: BuyerCompanyAPI['delivery_address_country']
  deliveryAddressLine: BuyerCompanyAPI['delivery_address_line']
  deliveryAddressLocality: BuyerCompanyAPI['delivery_address_locality']
  deliveryAddressZip: BuyerCompanyAPI['delivery_address_zip']
  deliveryCode: BuyerCompanyAPI['delivery_code']
  deliveryComments: BuyerCompanyAPI['delivery_comments']
  deliveryNoteWithPrices: BuyerCompanyAPI['delivery_note_with_prices']
  deliveryPhone: BuyerCompanyAPI['delivery_phone']
  deliveryTimeFrom: BuyerCompanyAPI['delivery_time_from']
  deliveryTimeTo: BuyerCompanyAPI['delivery_time_to']
  editable: BuyerCompanyAPI['editable']
  groupTags: BuyerCompanyAPI['group_tags']
  hasConnectedRoute: BuyerCompanyAPI['has_connected_route']
  id: BuyerCompanyAPI['id']
  internalReference: BuyerCompanyAPI['internal_reference']
  invoiceCycle: BuyerCompanyAPI['invoice_cycle']
  isDemoCompany: BuyerCompanyAPI['is_demo_company']
  legalName: BuyerCompanyAPI['legal_name']
  locality: BuyerCompanyAPI['locality']
  marketplaceIds: BuyerCompanyAPI['marketplace_ids']
  name: BuyerCompanyAPI['name']
  organisationPusherUuid: BuyerCompanyAPI['organisation_pusher_uuid']
  optionFlagCustomerCanEditPrices: BuyerCompanyAPI['option_flag_customer_can_edit_prices']
  organisationId: BuyerCompanyAPI['organisation_id']
  paymentTermsCycle: BuyerCompanyAPI['payment_terms_cycle']
  paymentTermsDays: BuyerCompanyAPI['payment_terms_days']
  registrationNumber: BuyerCompanyAPI['registration_number']
  requireOneInvoicePerOrder: BuyerCompanyAPI['require_one_invoice_per_order']
  schedulerStartingIsoDay: BuyerCompanyAPI['scheduler_starting_iso_day']
  specialFootnoteForInvoice: BuyerCompanyAPI['special_footnote_for_invoice']
  useOnlyFixedWeightBoxes: BuyerCompanyAPI['use_only_fixed_weight_boxes']
  uuid: BuyerCompanyAPI['uuid']
  vatNumber: BuyerCompanyAPI['vat_number']
  vatZoneId: BuyerCompanyAPI['vat_zone_id']
  verticalIds: BuyerCompanyAPI['vertical_ids']
  zipCode: BuyerCompanyAPI['zip_code']
}

export interface BuyerCompanyUpdateBody extends Omit<BuyerCompany, 'customerCategory'> {
  customerCategoryId: number | null
}

export interface BuyerCompanyCountry {
  code: string
  name: string
}

export interface BuyerCompanyAPIAsBuyer {
  can_import_invoices: boolean
  can_use_chat: boolean
  category: string
  count_future_orders: number
  country: BuyerCompanyCountry
  currency: string
  customer_invoice_statuses: MyOptionType<InvoiceStatuses>[]
  default_marketplace_id: Id
  delivery_address_country: string
  delivery_address_line: string
  delivery_address_locality: string
  delivery_address_zip: string
  first_order_date: string
  has_connected_route: boolean
  id: Id
  is_demo_company: boolean
  marketplace_ids: Id[]
  name: string
  option_flag_can_edit_order: boolean
  option_flag_can_manage_tours: boolean
  option_flag_can_use_claims: boolean
  option_flag_can_visualize_ordered_quantities_scheduler: boolean
  option_flag_disable_live: boolean
  option_flag_display_orders_tab: boolean
  option_flag_show_supplier_opportunities: boolean
  option_flag_customer_can_edit_prices: boolean
  opt_display_orders_summary_feature: boolean
  order_number: number
  scheduler_starting_iso_day: WeekDays
  uuid: string
  vertical_ids: Id[]
}

export interface BuyerCompanyAsBuyer {
  canImportInvoices: BuyerCompanyAPIAsBuyer['can_import_invoices']
  canUseChat: BuyerCompanyAPIAsBuyer['can_use_chat']
  category: BuyerCompanyAPIAsBuyer['category']
  countFutureOrders: BuyerCompanyAPI['count_future_orders']
  country: BuyerCompanyAPIAsBuyer['country']
  currency: BuyerCompanyAPIAsBuyer['currency']
  customerInvoiceStatuses: BuyerCompanyAPIAsBuyer['customer_invoice_statuses']
  defaultMarketplaceId: BuyerCompanyAPIAsBuyer['default_marketplace_id']
  deliveryAddressCountry: BuyerCompanyAPIAsBuyer['delivery_address_country']
  deliveryAddressLine: BuyerCompanyAPIAsBuyer['delivery_address_line']
  deliveryAddressLocality: BuyerCompanyAPIAsBuyer['delivery_address_locality']
  deliveryAddressZip: BuyerCompanyAPIAsBuyer['delivery_address_zip']
  firstOrderDate: BuyerCompanyAPIAsBuyer['first_order_date']
  hasConnectedRoute: BuyerCompanyAPIAsBuyer['has_connected_route']
  id: BuyerCompanyAPIAsBuyer['id']
  isDemoCompany: BuyerCompanyAPIAsBuyer['is_demo_company']
  marketplaceIds: BuyerCompanyAPIAsBuyer['marketplace_ids']
  name: BuyerCompanyAPIAsBuyer['name']
  optInvoiceCustomerStatusFeature: boolean
  optionFlagCanEditOrder: BuyerCompanyAPIAsBuyer['option_flag_can_edit_order']
  optionFlagCanManageTours: BuyerCompanyAPIAsBuyer['option_flag_can_manage_tours']
  optionFlagCanUseClaims: BuyerCompanyAPIAsBuyer['option_flag_can_use_claims']
  optionFlagCanVisualizeOrderedQuantitiesScheduler: BuyerCompanyAPIAsBuyer['option_flag_can_visualize_ordered_quantities_scheduler']
  optDisplayOrdersSummaryFeature: BuyerCompanyAPIAsBuyer['opt_display_orders_summary_feature']
  optionFlagCustomerCanEditPrices: BuyerCompanyAPIAsBuyer['option_flag_customer_can_edit_prices']
  optionFlagDisableLive: BuyerCompanyAPIAsBuyer['option_flag_disable_live']
  optionFlagDisplayOrdersTab: BuyerCompanyAPIAsBuyer['option_flag_display_orders_tab']
  optionFlagShowSupplierOpportunities: BuyerCompanyAPIAsBuyer['option_flag_show_supplier_opportunities']
  orderNumber: BuyerCompanyAPIAsBuyer['order_number']
  organisationId?: Id | null
  schedulerStartingIsoDay: BuyerCompanyAPIAsBuyer['scheduler_starting_iso_day']
  uuid: BuyerCompanyAPIAsBuyer['uuid']
  verticalIds: BuyerCompanyAPIAsBuyer['vertical_ids']
}
